import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import NavItem from './components/NavItem';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 200, md: 200 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? 'https://i.imgur.com/1HkRyzi.jpg'
                : 'https://i.imgur.com/1HkRyzi.jpg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'Home'} items={landingPages} />
        </Box>
        <Box>
          <NavItem title={'About Us'} items={companyPages} />
        </Box>
        <Box>
          <NavItem title={'Admissions'} items={accountPages} />
        </Box>
        <Box>
          <NavItem title={'Learning'} items={secondaryPages} />
        </Box>
        <Box>
          <NavItem title={'Our Campus'} items={blogPages} />
        </Box>
        {/* <Box>
          <NavItem title={'Circular'} items={portfolioPages} />
        </Box>
        <Box>
          <NavItem title={'Career'} items={portfolioPages} />
        </Box> */}
        <Box>
          <NavItem title={'Contact Us'} items={portfolioPages} />
        </Box>
        
        {/* <Box>
          <NavItem title={''} items={portfolioPages} />
        </Box> */}
        <Box marginTop={2}>
          <Button
            size={'large'}
            variant="outlined"
            fullWidth
            component="a"
            href="/virtual-tour"
          >
            Virtual Tour
          </Button>
        </Box>
        <Box marginTop={2} marginRight={2}>
                <Link
                  href="https://www.facebook.com/p/National-Public-School-Yeshwanthpur-100084410342747/"
                  color="text.primary"
                  target="_blank"
                  rel="noopener"
                >
                  <FacebookIcon />
                </Link>
              </Box>
              <Box marginTop={2} marginRight={2}>
                <Link
                  href="https://www.instagram.com/nps_ypr/"
                  color="text.primary"
                  target="_blank"
                  rel="noopener"
                >
                  <InstagramIcon />
                </Link>
              </Box>

        <Box marginTop={1}>
          <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            href="https://online-registrations.npsypr.edu.in/"
          >
            Online Registration
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;